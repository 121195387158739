import { InjectionToken, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { StoreSlice } from '@cjm/shared/store';

import { ProductViewConfiguration } from './interfaces';
import { reducers } from './product.store';
import { FavoriteProductsService, ProductApiService } from './services';

export const ProductViewInjectionTokenConfiguration = new InjectionToken<ProductViewConfiguration>(
	'ProductViewConfiguration'
);

@NgModule({
	imports: [StoreModule.forFeature(StoreSlice.Products, reducers)],
	providers: [ProductApiService, FavoriteProductsService]
})
export class ProductDataModule {}
